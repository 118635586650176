// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': 'eu-west-1',
    'aws_cognito_identity_pool_id': 'eu-west-1:1bf54f9c-dcb5-4100-9a3f-8aa9f3eaa34d',
    'aws_cognito_region': 'eu-west-1',
    'aws_user_pools_id': 'eu-west-1_q6tecsJl5',
    'aws_user_pools_web_client_id': '2f0rje7i0iuh4j10d18m7enff',
    'oauth': {}
};


export default awsmobile;